// Allows usage of global dayjs in a vue 3 composition like way via useDayJs()
/*
    Example in a vue setup method
    import useDayJs from '@/plugins/useEmitter'

    export default {
    setup() {
        const day = useDayJs()
        ...
    }
    ...
    }
*/
export default function useDayJs() {
    const internalInstance = getCurrentInstance();
    const dayjs = internalInstance.appContext.config.globalProperties.$dayjs;

    return dayjs;
}
