// store/session.js
import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import sanctum from '@/api/auth/sanctum';
import { cloneDeep } from 'lodash';
import Dinero from 'dinero.js';

import router from '../router';
import { useAppStore, useInventoryStore, useAnalyticsStore, useResultsStore } from '@/store';

export const useSessionStore = defineStore('session', {
    state: () => {
        return {
            isReady: false,
            loading: false,
            bookingType: useStorage('bookingType', 'WHOLE_HOME'),
            user: useStorage('user', {}),
            orderRef: useStorage('orderRef', null),
            referralToken: useStorage('referralToken', null),
            bearer: useStorage('bearer', null),
            hasXSRFTokenState: useStorage('hasXSRFTokenState', true),
            order: {},
            orderUpdateListener: null,
            preselect: useStorage('preselect', null),
            videoEmbed: null,
            preselectQuoteType: null,
        };
    },
    actions: {
        reset(bookingType) {
            // Uppercase the booking type if its passed
            if (bookingType) {
                bookingType = bookingType.toUpperCase();
            }

            this.isReady = false;
            this.loading = false;
            this.bookingType = bookingType ? bookingType : 'WHOLE_HOME';
            this.user = {};
            this.orderRef = null;
            this.referralToken = null;
            this.bearer = null;
            this.hasXSRFTokenState = false;
            this.order = {};
        },

        async init({ orderRef = null, token = null, isCreate = false, query = null, page = null } = {}) {
            if (isCreate) {
                this.loading = false;
            } else {
                this.loading = true;
            }

            // If orderRef and token are present use these to init the session
            if (orderRef && token) {
                this.orderRef = orderRef;
                this.referralToken = token;
            }

            try {
                // Check for auth token or orderRef
                if (this.referralToken && this.orderRef) {
                    const loginParams = {
                        token: this.referralToken,
                    };

                    // Check if valid auth data if not go to step 1
                    const { data } = await sanctum.login(loginParams);
                    this.bearer = data.token;

                    query = page ? { ...query, page } : query;
                    await this.fetchSession(isCreate, query);

                    this.isReady = true;
                }
            } catch (error) {
                console.error('⛔️ error in initialisation of session', error);

                throw error;
            } finally {
                this.loading = false;
            }
        },

        /*
         * Request a callback
         * If the product exists already:
         * Usage: requestCallback({ productable_type: ProductableType.MOVE });
         *
         * If it does NOT exist use service_type:
         * Usage: requestCallback({ service_type: PET_TRANSPORT });
         */
        async requestCallback(data) {
            const resp = await window.axios.post(`/customers/book/orders/${this.getOrderId}/callback`, data);
            switch (resp.data.inbox.inboxItem.service_type) {
                case 'MOVE':
                    this.order.has_requested_move_callback = true;
                    break;
                case 'CAR_TRANSPORT':
                    this.order.car_transport_callback_requested = true;
                    break;
                case 'PET_TRANSPORT':
                    this.order.pet_transport_callback_requested = true;
                    break;
                case 'UTILITIES':
                    this.order.has_requested_utilities_callback = true;
                    break;
            }

            return resp.data.inbox;
        },

        async retrieve(query = {}) {
            try {
                const { data } = await window.axios.get(`/customers/book/${this.orderRef}`, {
                    params: {
                        ...query,
                    },
                });

                // Set session state from response
                this.setSessionState({
                    order: data.order,
                    orderRef: data.order.ref,
                    token: data.order.customer.referral_token,
                    bookingType: data.order.primary_item.product.booking_type,
                });

                return data.order;
            } catch (error) {
                console.log('Error in retrieve', error);
                throw error;
            }
        },

        async fetchTotals() {
            try {
                const data = await window.axios.get(`/customers/book/${this.orderRef}/totals`);
                return data;
            } catch (error) {
                console.log('Error in fetchTotals', error);
                throw error;
            }
        },

        async fetchSession(isCreate, query = null) {
            try {
                if (isCreate) {
                    this.loading = false;
                } else {
                    this.loading = true;
                }

                this.hasXSRFTokenState = sanctum.hasXSRFToken();

                await sanctum.fetchCSRFToken();

                if (!isCreate) {
                    try {
                        window.axios.post(`/customers/book/return/${this.orderRef}`, {
                            referrer: window.frames.top.document.referrer,
                        });
                    } catch {
                        // do nothing if it fails
                    }
                }

                await this.retrieve(query);

                this.isReady = true;
            } catch (error) {
                this.user = null;
                this.bearer = null;
                this.hasXSRFTokenState = false;
                this.loading = false;

                throw error;
            }
        },

        // Sets the session state
        setSessionState({
            order = null,
            orderRef = null,
            token = null,
            bookingType = null,
            quoteType = null,
            inventory = null,
        }) {
            if (order) {
                this.order = cloneDeep(order);
            }

            if (bookingType) {
                // Uppercase the booking type
                bookingType = bookingType.toUpperCase();
                this.bookingType = bookingType;
            }

            if (quoteType) {
                this.quoteType = quoteType;
            }

            if (orderRef) {
                this.orderRef = orderRef;
            }

            if (token) {
                this.referralToken = token;
            }

            if (inventory) {
                const inventoryStore = useInventoryStore();
                inventoryStore.init({ inventory: inventory });
                this.getPrimaryProduct.inventory = inventory;
            }
        },

        afterConfirmation() {
            // Check if primary item is status CONFIRMED and if so, push user to confirmed
            if (this.getPrimaryItem?.status === 'CONFIRMED') {
                router.replace('/results/confirmed');
            }

            const analytics = useAnalyticsStore();

            if (
                this.order?.connection_request_status == 'CALLBACK' || // legacy - keep since some customers may have this status when booking
                this.order?.connection_request_status == 'SCHEDULED_CALLBACK' ||
                this.order?.connection_request_status == 'CONTACT_THIS_WEEK' || // legacy - keep since some customers may have this status when booking
                this.order?.connection_request_status == 'CONTACT_ASAP' ||
                this.order?.connection_request_status == 'CONTACT_NEXT_WEEK' ||
                this.order?.connection_request_status == 'CONTACT_REMIND_LATER'
            ) {
                analytics.bookConfirmedWithEnergy();
            }

            // always send book confirmed
            analytics.bookConfirmed();

            return true;
        },

        // Confirm a booking
        async confirmBooking(paymentIntent, items) {
            try {
                const { data } = await window.axios.post(
                    `/customers/book/orders/${this.order.id}/confirm${this.isRelocation ? '/request-approval' : ''}`,
                    {
                        pm_id: paymentIntent.payment_method,
                        seti_id: paymentIntent.id,
                        ...(items ? { items: items } : null),
                    },
                );

                if (data) {
                    this.setSessionState({ order: data.order });

                    return this.afterConfirmation();
                }

                return false;
            } catch (error) {
                console.log('Error sending payment intent', error);

                return error;
            }
        },

        // Set a booking as pending bank deposit
        async pendingBankDeposit(items) {
            try {
                const { data } = await window.axios.post(
                    `/customers/book/orders/${this.order.id}/confirm/bank-deposit`,
                    {
                        ...(items ? { items: items } : null),
                    },
                );

                if (data) {
                    this.setSessionState({ order: data.order });

                    return this.afterConfirmation();
                }

                return false;
            } catch (error) {
                console.log('Error sending pending bank transfer', error);

                return error;
            }
        },

        // Set a booking as pending bank deposit
        async requestApproval(items) {
            try {
                const { data } = await window.axios.post(
                    `/customers/book/orders/${this.order.id}/confirm/request-approval`,
                    {
                        ...(items ? { items: items } : null),
                    },
                );

                if (data) {
                    this.setSessionState({ order: data.order });

                    return this.afterConfirmation();
                }

                return false;
            } catch (error) {
                console.log('Error sending request approval', error);

                return error;
            }
        },

        setPrimaryProductInventory(inventory) {
            const primaryProduct = this.order.items.find((item) => item.id === this.order.primary_item.id);
            if (primaryProduct && primaryProduct.product) {
                primaryProduct.product.inventory = [...inventory];
            }
        },

        // Sync the session state with new insurance data
        async setInsurance(insuranceObject) {
            console.log('Setting insurance', insuranceObject);
            let totals = await this.fetchTotals();

            if (insuranceObject) {
                if (this.order.items.find((item) => item.service_type == 'Insurance')) {
                    this.order.items.find((item) => item.service_type == 'Insurance').product = insuranceObject;
                    this.order.items.find((item) => item.service_type == 'Insurance').totals = totals.data.items.find(
                        (item) => item.productable_type === 'insurance',
                    ).totals;
                } else {
                    this.order.items.push({
                        id: insuranceObject.order_item_id,
                        status: insuranceObject.order_item_status,
                        service_type: 'Insurance',
                        product: insuranceObject,
                        totals: totals.data.items.find((item) => item.productable_type === 'insurance').totals,
                    });
                }
            } else {
                let item = this.order.items.find((item) => item.service_type == 'Insurance');
                if (item) {
                    // Remove item
                    this.order.items.splice(this.order.items.indexOf(item), 1);
                }
            }
        },

        async fetchTerms() {
            if (!this.getMatchCompanyId) return null;

            try {
                const { data: data } = await window.axios.get(`/customers/book/${this.getMatchCompanyId}/terms`, {});
                return data?.terms?.url ?? null;
            } catch (error) {
                console.log('Error fetching terms', error);
                return null;
            }
        },

        setPreselect(preselect) {
            this.preselect = preselect;
        },
    },
    getters: {
        hasSession(state) {
            if (state.referralToken && state.orderRef) {
                return true;
            } else {
                return false;
            }
        },

        isLocal(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.type === 'LOCAL') {
                return true;
            } else {
                return false;
            }
        },

        isInventoryAutoApproved(state) {
            if (
                state.getSelectedMatch &&
                (state.getSelectedMatch.pricing_type === 'VARIABLE' || state.getSelectedMatch.move_type === 'LOCAL')
            ) {
                return true;
            } else {
                return false;
            }
        },

        getBookingType(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.booking_type) {
                return state.getPrimaryProduct.booking_type;
            } else {
                console.log('No booking type found', state.bookingType);

                return state.bookingType;
            }
        },

        getOrder(state) {
            if (state.order) {
                return state.order;
            } else {
                return null;
            }
        },

        getOrderId(state) {
            if (state.order && state.order.id) {
                return state.order.id;
            } else {
                return null;
            }
        },

        cardFeesEnabled(state) {
            const appStore = useAppStore();

            if (state.order) {
                return appStore?.cardFeesEnabled && state.order?.card_fee;
            } else {
                return appStore?.cardFeesEnabled;
            }
        },

        getOrderRef(state) {
            if (state.orderRef) {
                return state.orderRef;
            } else {
                return null;
            }
        },

        getOrderSensitive(state) {
            if (state.order) {
                return state.order.sensitive;
            } else {
                return null;
            }
        },

        isLocked(state) {
            if (state.order) {
                return state.order.locked;
            } else {
                return null;
            }
        },

        getToken(state) {
            if (state.referralToken) {
                return state.referralToken;
            } else {
                return null;
            }
        },

        getPreselectQuoteType(state) {
            if (state.preselectQuoteType) {
                return state.preselectQuoteType;
            } else {
                return null;
            }
        },

        getQuoteType(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.quote_type;
            } else {
                return null;
            }
        },

        /* Return the route saved on the move object */
        getRoute(state) {
            if (state.order && state.getPrimaryProduct) {
                console.info(`Route selection getRoute form_path: ${state.getPrimaryProduct.form_step}`);

                return state.getPrimaryProduct.form_step;
            }
        },

        getUser(state) {
            return state.user;
        },

        getCustomer(state) {
            return state.order?.customer ?? {};
        },

        getCustomerEmail(state) {
            if (state.order.customer && state.order.customer.email) {
                return state.order.customer.email;
            }
            return null;
        },

        // Returns the primary item object
        //todo may have to split this up into a getCustomer if the same key exists in customer later
        getPrimaryItem(state) {
            if (state.order.primary_item?.id) {
                const primaryItem = state.order.items.find((item) => item.id === state.order.primary_item.id);
                return {
                    customer: state.order.customer,
                    ...primaryItem,
                };
            }
            return null;
        },

        getMove(state) {
            const moveOrderItem = state.order?.items?.find((item) => item.productable_type === 'move').product;
            return {
                ...moveOrderItem,
            };
        },

        getMoveStatus(state) {
            const status = state.order?.items?.find((item) => item.productable_type === 'move').product.status;
            return status;
        },

        isInterstate(state) {
            if (state.getMove) {
                return state.getMove.type == 'INTERSTATE';
            } else {
                return false;
            }
        },

        isSelfPack(state) {
            return state.getSelectedMatch?.isSelfPack;
        },

        isSelfy(state) {
            return state.getSelectedMatch?.isSelfy;
        },

        isTaxibox(state) {
            return state.getSelectedMatch?.isTaxibox;
        },

        getMoveId(state) {
            const moveOrderItem = state.order?.items?.find((item) => item?.productable_type === 'move');
            return moveOrderItem?.productable_id;
        },

        getMoveUpdatedAt(state) {
            const moveOrderItem = state.order?.items?.find((item) => item?.productable_type === 'move');
            return moveOrderItem?.product.updated_at;
        },

        // Return the primary item and customer from the order
        //todo may have to split this up into a getCustomer if the same key exists in customer later
        getPrimaryProduct(state) {
            if (state.getPrimaryItem) {
                return {
                    customer: state.order.customer,
                    ...state.getPrimaryItem.product,
                };
            }
            return {};
        },

        getInsurancePolicyType(state) {
            return state?.order?.insurance_policy_type;
        },

        getInsurancePolicyBrand(state) {
            return state?.order?.insurance_policy_brand;
        },

        getInsurancePolicyVerbPresent(state) {
            return state?.order?.insurance_policy_verb_present;
        },

        getInsurancePolicyVerbPast(state) {
            return state?.order?.insurance_policy_verb_past;
        },

        getInsurancePolicyPremiumLabel(state) {
            return state?.order?.insurance_policy_premium_label;
        },

        getInsurancePolicyDocumentLabel(state) {
            return state?.order?.insurance_policy_document_label;
        },

        // Get the OrderItem object for insurance
        getInsuranceItem(state) {
            return state?.order?.items?.find((item) => item.service_type == 'Insurance') ?? null;
        },

        // Get the product object of the insurances OrderItem
        getInsuranceItemProduct(state) {
            return state.getInsuranceItem?.product ?? null;
        },

        hasInsurance(state) {
            return !!state.getInsuranceItem;
        },

        isInsuranceAvailable(state) {
            return (
                state.getPickupDwelling !== 'STORAGE' &&
                !state.isSelfPack &&
                (!state.isRelocation || state.getRelocation.services_allowed.includes('INSURANCE'))
            );
        },

        getOrderStatus(state) {
            if (state.order && state.getPrimaryItem) {
                return state.getPrimaryItem.status;
            } else {
                return null;
            }
        },

        getSelectedMatchDeposit(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.selectedMatch) {
                return state.getPrimaryProduct.selectedMatch.deposit.formatted;
            } else {
                return null;
            }
        },

        getSelectedMatchSpace(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.selectedMatch) {
                return state.getPrimaryProduct.selectedMatch.quoted_space;
            } else {
                return null;
            }
        },

        getSelectedMatchPremium(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.selectedMatch) {
                return state.getPrimaryProduct.selectedMatch.isPremium;
            } else {
                return null;
            }
        },

        getSelectedMatchShared(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.selectedMatch) {
                return !state.getPrimaryProduct.selectedMatch.isPremium;
            } else {
                return null;
            }
        },

        getItemsDepositAmount(state) {
            if (state.order && state.order.items) {
                let total = 0;

                state.order.items.forEach((item) => {
                    total += Number.parseInt(item.totals.DEPOSIT.amount);
                });

                // Format total from cents to dollars
                return Dinero({
                    amount: total,
                }).toFormat('$0,0.00');
            } else {
                return 0;
            }
        },

        getItemsDepositAmountWithoutConfirmed(state) {
            if (state.order && state.order.items) {
                let total = 0;
                state.order.items.forEach((item) => {
                    if (item.status !== 'CONFIRMED') {
                        total += Number.parseInt(item.totals.DEPOSIT.amount);
                    }
                });

                // Format total from cents to dollars
                return Dinero({
                    amount: total,
                }).toFormat('$0,0.00');
            } else {
                return 0;
            }
        },

        getItemsDepositAmountOwing(state) {
            if (state.order && state.order.items) {
                let total = 0;
                state.order.items.forEach((item) => {
                    if (item.service_status == 'Ready' || item.service_status == 'Accepted') {
                        // todo we need a better bool to check this kinda logic maybe OrderItem resource should return a is_paid flag?
                        // exclude since READY or ACCEPTED indicates existing charge exists so this is a new payment
                    } else {
                        total += Number.parseInt(item.totals.DEPOSIT.amount);
                    }
                });

                // Format total from cents to dollars
                return Dinero({
                    amount: total,
                }).toFormat('$0,0.00');
            } else {
                return 0;
            }
        },

        getItemsBalanceAmount(state) {
            if (state.order && state.order.items) {
                let total = 0;
                state.order.items.forEach((item) => {
                    if (item?.totals) {
                        total += Number.parseInt(item.totals.BALANCE.amount);
                    }
                });

                // Format total from cents to dollars
                return Dinero({
                    amount: total,
                }).toFormat('$0,0.00');
            } else {
                return 0;
            }
        },

        getItemsTotalAmountFormatted(state) {
            if (state.order && state.order.items) {
                let total = 0;
                state.order.items.forEach((item) => {
                    total += Number.parseInt(item.totals.TOTAL.amount);
                });

                // Format total from cents to dollars
                return Dinero({
                    amount: total,
                }).toFormat('$0,0.00');
            }
            return 0;
        },

        getItemsTotalAmount(state) {
            if (state.order && state.order.items) {
                let total = 0;
                state.order.items.forEach((item) => {
                    total += Number.parseInt(item.totals.TOTAL.amount);
                });

                // Format total from cents to dollars
                return Dinero({
                    amount: total,
                }).getAmount();
            }
            return 0;
        },

        getTimeEstimateFormatted(state) {
            const timeEst = state.getPrimaryProduct?.['time_est'];

            if (!timeEst) return ''; // or return some default value

            if (timeEst.min) {
                return `${timeEst.min} - ${timeEst.max} hrs`;
            } else {
                return `${timeEst.time} hrs`;
            }
        },

        hasInventory(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.latest_inventory_space) {
                return true;
            } else {
                return false;
            }
        },

        hasLatestInventory(state) {
            if (
                state.order &&
                state.getPrimaryProduct &&
                state.getPrimaryProduct.latest_inventory &&
                state.getPrimaryProduct.latest_inventory.length > 0
            ) {
                return true;
            } else {
                return false;
            }
        },

        hasPendingInventory(state) {
            // Local move will never have pending inventory
            if (
                state.order &&
                state.getPrimaryProduct &&
                state.getPrimaryProduct.pending_inventory &&
                state.getPrimaryProduct.pending_inventory.length
            ) {
                return true;
            } else {
                return false;
            }
        },

        isPendingBankDeposit(state) {
            if (state.getPrimaryItem) {
                return state.getPrimaryItem.is_pending_bank_deposit;
            }

            return false;
        },

        isPendingApproval(state) {
            if (state.getPrimaryItem) {
                return state.getPrimaryItem.is_pending_approval;
            }

            return false;
        },

        canRequestApproval(state) {
            if (state.getRelocation) {
                return !state.getRelocation.is_service_missing;
            }

            return false;
        },

        hasSpace(state) {
            return !!(state.order && state.getPrimaryProduct && state.getPrimaryProduct.space);
        },

        hasMatches() {
            const results = useResultsStore();
            return results.hasMatches;
        },

        hasSelectedMatch(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.selectedMatch) {
                return true;
            }
            return false;
        },

        getSelectedMatch(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.selectedMatch) {
                // Return selectedMatch
                return state.getPrimaryProduct.selectedMatch;
            } else {
                return null;
            }
        },

        isSelectedMatchWithinFiveDays(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.selectedMatch) {
                // Return selectedMatch
                return state.getPrimaryProduct.selectedMatch.within_week;
            } else {
                return false;
            }
        },

        isSelectedMatchCustom(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.selectedMatch) {
                // Return selectedMatch
                return (
                    state.getPrimaryProduct.selectedMatch.match_type === 'CUSTOM' ||
                    state.getPrimaryProduct.selectedMatch.match_type === 'MUVEXPRESS'
                );
            } else {
                return null;
            }
        },

        getCustomerFirstName(state) {
            if (state.order.customer && state.order.customer.first_name) {
                return state.order.customer.first_name;
            }
            return null;
        },

        getCustomerLastName(state) {
            if (state.order && state.order.customer) {
                return state.order.customer.last_name;
            } else {
                return null;
            }
        },

        getCustomerFullName(state) {
            if (state.order.customer) {
                return state.order.customer.first_name + ' ' + state.order.customer.last_name;
            } else {
                return null;
            }
        },

        getCustomerPhone(state) {
            if (state.order.customer) {
                return state.order.customer.phone_raw;
            } else {
                return null;
            }
        },

        getCustomerNotes(state) {
            if (state.getPrimaryProduct) {
                return state.getPrimaryProduct.customer_notes;
            } else {
                return null;
            }
        },

        getCustomQuoteNotes(state) {
            if (state.getSelectedMatch) {
                return state.getSelectedMatch.notes;
            } else {
                return null;
            }
        },

        getPickupNotes(state) {
            return state.getPrimaryProduct?.pickup_notes ?? null;
        },

        getDeliveryNotes(state) {
            return state.getPrimaryProduct?.delivery_notes ?? null;
        },

        getSpace(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.space;
            }
        },

        getEstimatedSpace(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.estimated_space;
            }
        },

        getInventory(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct?.inventory?.length) {
                return state.getPrimaryProduct.inventory;
            }

            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct?.pending_inventory?.length) {
                return state.getPrimaryProduct?.pending_inventory;
            }
        },

        getInventoryCount(state) {
            if (state.getInventory) {
                // loop inventory array and return the total count
                let count = 0;
                state.getInventory.forEach((item) => {
                    count += item.qty;
                });
                return count;
            } else {
                return 0;
            }
        },

        getCurrentInventorySpace(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct?.current_inventory_space) {
                return state.getPrimaryProduct.current_inventory_space;
            }
        },

        getCurrentInventoryQty(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct?.current_inventory_qty) {
                return state.getPrimaryProduct.current_inventory_qty;
            }
        },

        getPendingInventory(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.pending_inventory;
            }
        },

        getPendingInventorySpace(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.pending_inventory_space;
            }
        },

        getPendingInventoryCount(state) {
            let count = 0;
            state.getPendingInventory?.forEach((item) => {
                count += item.qty;
            });
            return count;
        },

        getPickupDateFlexibility(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.pickup_date_flexibility;
            }
        },

        getPickupDateFlexibilityFormatted(state) {
            if (state.getPickupDateFlexibility) {
                return state.getPrimaryProduct.pickup_date_flexibility_formatted;
            }
        },

        getPickupDwelling(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.pickup_dwelling;
            }
        },

        getDeliveryDwelling(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.delivery_dwelling;
            }
        },

        getPickupBedrooms(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.pickup_bedrooms;
            }
        },

        getDeliveryBedrooms(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.delivery_bedrooms;
            }
        },

        getRequestedOnsiteInspection(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.onsite_inspection;
            }
        },

        getPickupAccess(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.pickup_access;
            }
        },

        getDeliveryAccess(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.delivery_access;
            }
        },

        getPickupArea(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.pickup_area;
            }
        },

        getDeliveryArea(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.delivery_area;
            }
        },

        isMatching(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.status) {
                return state.getPrimaryProduct.status === 'MATCHING';
            } else {
                return false;
            }
        },

        isCompleted(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.status === 'COMPLETED') {
                return true;
            } else {
                return false;
            }
        },

        hasConfirmed(state) {
            if (state.order && state.getPrimaryItem && state.getPrimaryItem.status === 'CONFIRMED') {
                return true;
            } else {
                return false;
            }
        },

        hasRecommendedMatches(state) {
            return state?.getPrimaryProduct?.has_recommended_matches;
        },

        hasReachedResults(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.form_results;
            }
        },

        hasMoveBeenAccepted(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.has_been_accepted;
            } else {
                return false;
            }
        },

        getJob(state) {
            if (state.order && state.getPrimaryProduct && state.getPrimaryProduct.job) {
                return state.getPrimaryProduct.job;
            } else {
                return null;
            }
        },

        getJobStatus(state) {
            if (state.getJob) {
                return state.getJob.status;
            } else {
                return null;
            }
        },

        isJobPending(state) {
            if (state.getJob) {
                return state.getJob.status === 'PENDING';
            } else {
                return true;
            }
        },

        isJobAccepted(state) {
            if (state.getJob) {
                return state.getJob.status === 'ACCEPTED';
            } else {
                return true;
            }
        },

        isJobReady(state) {
            if (state.getJob) {
                return state.getJob.status === 'READY';
            } else {
                return true;
            }
        },

        isJobCompleted(state) {
            if (state.getJob) {
                return state.getJob.status === 'COMPLETED';
            } else {
                return true;
            }
        },

        isJobCancelled(state) {
            if (state.getJob) {
                return state.getJob.status === 'CANCELLED';
            } else {
                return true;
            }
        },

        isJobDeclined(state) {
            if (state.getJob) {
                return state.getJob.status === 'DECLINED';
            } else {
                return true;
            }
        },

        getMatchCompany(state) {
            if (state.getSelectedMatch) {
                return state.getSelectedMatch.company;
            } else {
                return null;
            }
        },

        getMatchCompanyId(state) {
            if (state.getSelectedMatch && state.getSelectedMatch.company) {
                return state.getSelectedMatch.company.id;
            } else {
                return null;
            }
        },

        getMoveTotals(state) {
            if (state.order?.items?.length) {
                return state.order.items[0].totals;
            } else {
                return null;
            }
        },

        hasPickupAddress(state) {
            if (state.order && state.getPrimaryProduct) {
                return state.getPrimaryProduct.pickup_address;
            }
        },

        getInsuranceTotals(state) {
            return state.order?.items?.find((item) => item.productable_type == 'insurance')?.totals;
        },

        getInsuranceId(state) {
            return state.order?.items?.find((item) => item.productable_type == 'insurance')?.productable_id;
        },

        hasPaidDeposit(state) {
            if (state.getJob) {
                return state.getJob.deposit_paid == true;
            }
        },

        hasPaidFinal(state) {
            if (state.getJob) {
                return state.getJob.final_paid == true;
            }
        },

        isUtilitiesPreselected(state) {
            return state.preselect == 'utilities';
        },

        isMuvalConnectReferral(state) {
            return state.order?.muval_connect_referral;
        },

        getAllowedConnectionPartner(state) {
            return state.order?.allowed_connection_partner;
        },

        getDisplayedConnectionPartner(state) {
            return state.order?.displayed_connection_partner;
        },

        hasSupportedConnectionPartner(state) {
            // utility connections are not supported for Corporate Relocations at this stage
            if (state.isRelocation) {
                return false;
            }

            return state.getAllowedConnectionPartner == 'MY_CONNECT' || state.getAllowedConnectionPartner == 'TEN_ANTS';
        },

        hasDisplayableConnectionPartner(state) {
            // utility connections are not supported for Corporate Relocations at this stage
            if (state.isRelocation) {
                return false;
            }

            return (
                state.getDisplayedConnectionPartner == 'MY_CONNECT' || state.getDisplayedConnectionPartner == 'TEN_ANTS'
            );
        },

        hasUtilitiesStep(state) {
            return state.hasDisplayableConnectionPartner;
        },

        hasInsuranceStep(state) {
            return state.isRelocation && state.getRelocation.services_allowed.includes('INSURANCE');
        },

        isInsuranceRequired(state) {
            return state.isRelocation && state.getRelocation.services_required.includes('INSURANCE');
        },

        hasUtilitiesCashBackOffer(state) {
            return state.getAllowedConnectionPartner == 'MY_CONNECT';
        },

        hasCustomerDetails(state) {
            return state.order.customer && state.order.customer.first_name;
        },

        getFromAddressFormatted(state) {
            return `${state.getPrimaryProduct.pickup_city}, ${state.getPrimaryProduct.pickup_state}`;
        },

        getToAddressFormatted(state) {
            return `${state.getPrimaryProduct.delivery_city}, ${state.getPrimaryProduct.delivery_state}`;
        },

        isJobWithinTwoDays(state) {
            if (state.getJob) {
                return state.getJob.days_until_uplift > 0 && state.getJob.days_until_uplift <= 2;
            }
        },

        hasOtherOptions(state) {
            return state.order?.has_other_options;
        },

        isRelocation(state) {
            return state.order?.is_relocation ?? false;
        },

        getRelocation(state) {
            return state.order?.relocation ?? {};
        },

        getAffiliate(state) {
            return state.order?.affiliate ?? {};
        },

        getReferrerLabel(state) {
            return state.order?.referrer_label ?? null;
        },

        hasExpired(state) {
            return state.getRelocation?.has_expired;
        },

        isRelocationWithinBudget(state) {
            if (state.isRelocation && state.order && state.order.items) {
                let total = 0;

                state.order.items.forEach((item) => {
                    if (item?.totals) {
                        total += Number.parseInt(item.totals.BALANCE.amount);
                    }
                });

                return total === 0;
            }

            return false;
        },

        allowMatchSelection(state) {
            if (state.isRelocation) {
                if (state.getRelocation.policy?.customer_can_select) {
                    return true;
                } else {
                    return false;
                }
            }

            return true;
        },
    },
});
