<template>
    <div class="relative h-full w-full p-0">
        <div class="relative flex h-full w-full cursor-pointer select-none">
            <input
                ref="inputRef"
                :disabled="disabled"
                :name="vid"
                class="peer block h-full w-full resize-y overflow-y-auto rounded-muval border pl-5 pr-1 text-right text-sm placeholder-transparent focus:border-focus focus:outline-none focus:ring-0"
                :class="{
                    'border-error pr-6': errorMessage,
                    'border-muval-gray-4 bg-white': !errorMessage,
                    'placeholder-gray-400': !label,
                }"
                :placeholder="label"
                autocomplete="no"
                type="text"
            />
            <div
                v-if="label"
                class="peer pointer-events-none absolute -top-2.5 left-2 z-0 flex text-sm text-gray-400 transition-all duration-75 peer-placeholder-shown:left-4 peer-placeholder-shown:top-[0.5rem] peer-placeholder-shown:text-base peer-focus:-top-2.5 peer-focus:left-2 peer-focus:text-sm peer-focus:text-focus"
            >
                <div class="z-10 flex px-2">
                    {{ label }}

                    <Tippy
                        v-if="tooltip"
                        max-width="none"
                        placement="bottom-start"
                        :offset="[0, 0]"
                        trigger="click"
                        animation="shift-away"
                        :interactive="true"
                        :delay="[10, 0]"
                        :duration="[200, 200]"
                    >
                        <template #default>
                            <button
                                class="pointer-events-auto my-auto ml-2"
                                @click.prevent
                            >
                                <svg
                                    width="13"
                                    height="13"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.7996 9.67499H6.9082V8.5664H5.7996V9.67499ZM6.3539 0.808594C5.25792 0.808594 4.18654 1.13359 3.27527 1.74249C2.36399 2.35138 1.65373 3.21683 1.23432 4.22939C0.814901 5.24195 0.705163 6.35614 0.918979 7.43107C1.1328 8.50599 1.66056 9.49338 2.43554 10.2684C3.21052 11.0433 4.1979 11.5711 5.27283 11.7849C6.34776 11.9987 7.46195 11.889 8.4745 11.4696C9.48706 11.0502 10.3525 10.3399 10.9614 9.42863C11.5703 8.51735 11.8953 7.44598 11.8953 6.34999C11.8947 4.88052 11.3106 3.47141 10.2716 2.43233C9.23249 1.39325 7.82338 0.809227 6.3539 0.808594ZM6.3539 10.7828C5.47718 10.7828 4.62014 10.5228 3.89117 10.0357C3.1622 9.54865 2.59404 8.85634 2.25853 8.04635C1.92302 7.23637 1.83524 6.34508 2.00628 5.4852C2.17732 4.62532 2.5995 3.83547 3.21944 3.21553C3.83938 2.59559 4.62923 2.17341 5.48911 2.00237C6.34899 1.83133 7.24027 1.91911 8.05026 2.25462C8.86025 2.59013 9.55256 3.15829 10.0396 3.88726C10.5267 4.61623 10.7867 5.47327 10.7867 6.34999C10.785 7.52513 10.3175 8.65165 9.48651 9.4826C8.65556 10.3135 7.52904 10.7811 6.3539 10.7828ZM6.3539 3.02499C6.06281 3.02489 5.77455 3.08215 5.5056 3.1935C5.23665 3.30484 4.99227 3.4681 4.78644 3.67393C4.5806 3.87976 4.41735 4.12414 4.306 4.39309C4.19466 4.66205 4.1374 4.9503 4.1375 5.2414H5.2453C5.25305 4.95263 5.3732 4.67828 5.58018 4.47677C5.78717 4.27526 6.06463 4.16251 6.35351 4.16251C6.64238 4.16251 6.91984 4.27526 7.12683 4.47677C7.33381 4.67828 7.45396 4.95263 7.46171 5.2414C7.46171 6.34999 5.7996 6.21142 5.7996 8.0121H6.9082C6.9082 6.76492 8.5703 6.62635 8.5703 5.2414C8.57041 4.9503 8.51315 4.66205 8.4018 4.39309C8.29046 4.12414 8.1272 3.87976 7.92137 3.67393C7.71554 3.4681 7.47116 3.30484 7.20221 3.1935C6.93325 3.08215 6.64499 3.02489 6.3539 3.02499Z"
                                        fill="#808080"
                                    />
                                </svg>
                            </button>
                        </template>

                        <template #content>
                            <div class="mb-3 rounded-md bg-white bg-opacity-80 px-3 py-1 text-xs text-black">
                                {{ tooltip }}
                            </div>
                        </template>
                    </Tippy>
                </div>
                <div class="absolute top-2.5 -z-10 h-0.5 w-full bg-white px-2"></div>
            </div>

            <div
                class="peer pointer-events-none absolute left-2 z-0 flex h-full pt-0.5 text-gray-400 peer-focus:text-focus"
                :class="errorMessage ? 'text-red-500' : ''"
            >
                <div class="my-auto">$</div>
            </div>

            <div
                v-if="infoType"
                :class="errorMessage ? 'border-red-500' : 'border-[#D0D0D0]'"
                class="peer pointer-events-none absolute right-0 top-0 z-0 flex h-input w-auto rounded-r-muval border border-l-0 border-gray-300 px-4 text-black peer-focus:border-focus"
            >
                <div class="relative">
                    <span>{{ infoType }}</span>
                    <div
                        v-if="errorMessage && infoType"
                        class="absolute -left-5 top-1/2 -translate-y-1/2 transform"
                    >
                        <svg
                            width="14"
                            height="13"
                            viewBox="0 0 14 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.24598 9.71591L5.92852 9.71591L5.92852 8.42046L7.24598 8.42046L7.24598 9.71591ZM7.24598 7.125L5.92852 7.125L5.92852 3.23864L7.24598 3.23864L7.24598 7.125ZM6.58725 12.9546C10.2234 12.9546 13.1746 10.0527 13.1746 6.47728C13.1746 2.90182 10.2234 -7.30522e-07 6.58725 -1.0431e-06C2.95106 -1.35567e-06 -5.34657e-05 2.90182 -5.37836e-05 6.47727C-5.41014e-05 10.0527 2.95106 12.9546 6.58725 12.9546ZM6.58725 1.29545C9.49226 1.29545 11.8571 3.6208 11.8571 6.47728C11.8571 9.33375 9.49226 11.6591 6.58725 11.6591C3.68225 11.6591 1.31741 9.33375 1.31741 6.47727C1.31741 3.6208 3.68225 1.29545 6.58725 1.29545Z"
                                fill="#D90000"
                            />
                        </svg>
                    </div>
                </div>
            </div>

            <div
                v-if="errorMessage && !infoType"
                class="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 transform text-error"
            >
                <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.24598 9.71591L5.92852 9.71591L5.92852 8.42046L7.24598 8.42046L7.24598 9.71591ZM7.24598 7.125L5.92852 7.125L5.92852 3.23864L7.24598 3.23864L7.24598 7.125ZM6.58725 12.9546C10.2234 12.9546 13.1746 10.0527 13.1746 6.47728C13.1746 2.90182 10.2234 -7.30522e-07 6.58725 -1.0431e-06C2.95106 -1.35567e-06 -5.34657e-05 2.90182 -5.37836e-05 6.47727C-5.41014e-05 10.0527 2.95106 12.9546 6.58725 12.9546ZM6.58725 1.29545C9.49226 1.29545 11.8571 3.6208 11.8571 6.47728C11.8571 9.33375 9.49226 11.6591 6.58725 11.6591C3.68225 11.6591 1.31741 9.33375 1.31741 6.47727C1.31741 3.6208 3.68225 1.29545 6.58725 1.29545Z"
                        fill="currentColor"
                    />
                </svg>
            </div>
        </div>
        <div
            class="pointer-events-none absolute -bottom-5 left-0 w-full truncate whitespace-nowrap text-xxs"
            :class="errorMessage ? 'text-red-500' : ''"
        >
            <span class="ml-2 text-xxs">{{ errorMessage }}</span>
        </div>
    </div>
</template>

<script setup>
import { useCurrencyInput } from 'vue-currency-input';
import { useField } from 'vee-validate';
import { idGen } from '@/utilities/idGen';
import { watchDebounced, promiseTimeout } from '@vueuse/core';

const isUnmounted = ref(false);

const props = defineProps({
    label: {
        type: [String, null],
        required: false,
        default: null,
    },
    vid: {
        type: String,
        required: false,
        default: () => idGen(6),
    },
    infoType: {
        type: [String, null],
        required: false,
        default: null,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    editing: {
        type: Boolean,
        required: false,
        default: true,
    },
    tooltip: {
        type: [String, null],
        required: false,
        default: null,
    },
});

const { numberValue, inputRef, setValue } = useCurrencyInput({
    currency: 'AUD',
    exportValueAsInteger: true,
    currencyDisplay: 'hidden',
    hideGroupingSeparatorOnFocus: false,
    hideNegligibleDecimalDigitsOnFocus: false,
    valueScaling: 'precision',
});

const { errorMessage, value: inputValue, handleChange } = useField(toRef(props, 'vid'));

onMounted(async () => {
    isUnmounted.value = false;

    await promiseTimeout(50);

    setValue(inputValue.value);
});

watchDebounced(
    numberValue,
    (value) => {
        if (isUnmounted.value) {
            return;
        }

        handleChange(value);
    },
    {
        debounce: 1000,
    },
);

onUnmounted(() => {
    isUnmounted.value = true;
});
</script>
